import '../styles/InstructionNext.css';

interface InstructionNextProps {
    onClick: any,
}

function InstructionNext(props: InstructionNextProps) {
    return (
        <div><button id="InstructionNext" onClick={props.onClick}>NEXT</button></div>
    )
}

export default InstructionNext