import InstructionNext from '../InstructionNext';

interface BreakProps {
    next: any,
    outOf: string
}

function InterSequenceBreak(props: BreakProps) {
    return (
        <div style={{textAlign: "center", width: "80%", margin: "auto", fontFamily: "Helvetica"}}>
            <h1 style={{marginBottom: 0, marginTop: 50, color: "red"}}>You've finished {props.outOf} sequences</h1>
            <h3 style={{marginTop: 0, fontWeight: "normal"}}>This is a great time to take a break if you need one. Click 'NEXT' to keep going.
            <br/><br/><InstructionNext onClick={props.next} /></h3>
        </div>
    )
}

export default InterSequenceBreak;