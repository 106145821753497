import { config } from "../scripts/config";
import '../styles/ImageOptions_Style.css';
import React, { useState } from 'react';

interface ImageOptionsProps {
    next: any,
    dontKnow: boolean
}

function ImageOptions(props: ImageOptionsProps) {
    const [currentStage, setCurrentStage] = useState(0);
    const [prevValue, setPrevValue] = useState("");
    const inputRef: any = React.createRef();

    const goNext = (e: any) => {
        if (e.target.id == "DK") {
            props.next("DONT_KNOW");
        } else {
            setCurrentStage(1);
        }
    }

    const submitGuess = (e: any) => {
        if (inputRef.current.value == "") {
            inputRef.current.value = "You must enter a guess!";
            inputRef.current.style.color = "red";
            setTimeout(() => {
                inputRef.current.value = "";
                inputRef.current.style.color = "black";
            }, 1500);
            return;
        } else if (inputRef.current.value == "You must enter a guess!") {
            return;
        }
        setPrevValue(inputRef.current.value);
        setCurrentStage(0);
        props.next(inputRef.current.value);
    }

    const sameGuess = () => {
        props.next(prevValue);
    }

    let sameguessbtn = <div></div>
    if (!props.dontKnow) {
        sameguessbtn = <button onClick={sameGuess} id="SG">Same Guess as Previous</button>
    }

    if (currentStage == 0) {
        return (
            <div className="ButtonGrid">
                <button id="DK" style={{width:config.imageSqH/2}} onClick={goNext}>Don't Know</button>
                <button id="G" style={{width:config.imageSqH/2}} onClick={goNext}>Guess</button>
                {sameguessbtn}
            </div>
        )
    } else if (currentStage == 1) {
        return (
            <div className="ButtonGrid" style={{alignItems: "center"}}>
                <input ref={inputRef} style={{width: 0.75*config.imageSqH, height: "100%", gridColumn: 1, marginRight: 10}} type="text" />
                <button onClick={submitGuess} style={{width: 0.25*config.imageSqH, height: "100%", gridColumn: 2}}>Submit</button>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
    
}

export default ImageOptions;