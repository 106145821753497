import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ShowSample from './components/Instructions/ShowSample';
import InstructionsPage from './components/Instructions/InstructionsPage';
import WelcomePage from './components/Instructions/WelcomePage';
import hitid_setup from './scripts/hitid_setup';

function HomePage() {
    const [currentInstruction, setCurrentInstruction] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();

    hitid_setup(searchParams);

    const nextInstruction = () => {
        if ((currentInstruction + 1) === 3) {
            navigate("/practice");
        }
        setCurrentInstruction(currentInstruction + 1);
    }
    
    if (currentInstruction == 0) {
        return (
            <WelcomePage nextOnClick={nextInstruction} />
        )
    } else if (currentInstruction == 1) {
        return (
            <InstructionsPage nextOnClick={nextInstruction} />
        )
    } else if (currentInstruction == 2) {
        return (
            <ShowSample next={nextInstruction} />
        )
    } else {
        return (
            <div></div>
        )
    }
}

export default HomePage;