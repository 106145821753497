import React from 'react';
import { config } from './scripts/config';
import ImageShow from './components/ExpScreens/ImageShow';
import InterSequenceBreak from './components/ExpScreens/InterSequenceBreak';
import Finished from './components/ExpScreens/Finished';

const axios = require('axios')

interface ExpProps {
    type: string
}

interface ExpState {
    loaded: boolean,
    sequences: Array<Array<string>>,
    toSubmit: any,
    waited: boolean,
    currentSequence: number,
    currentImage: number,
    intersequencebreak: boolean,
    dontKnow: boolean,
    finished: boolean,
    hitid: string,
    prolific: boolean,
    exitedfs: boolean,
}

class Exp extends React.Component<ExpProps, ExpState> {
    private bigRef: any;
    private time: any;

    constructor(props: ExpProps) {
        super(props);

        this.state = {
            loaded: false,
            sequences: [],
            toSubmit: {},
            waited: false,
            currentSequence: 0,
            currentImage: 0,
            intersequencebreak: false,
            dontKnow: true,
            finished: false,
            hitid: "",
            prolific: false,
            exitedfs: false
        }

        this.time = -99;
        this.readyToStart = this.readyToStart.bind(this);
        this.getResponse = this.getResponse.bind(this);
        this.afterBreak = this.afterBreak.bind(this);
        this.fullscreenChanged = this.fullscreenChanged.bind(this);
        this.bigRef = React.createRef();
    }

    readyToStart() {
        this.bigRef.current.webkitRequestFullscreen();
        if (!this.state.waited) {
            this.bigRef.current.addEventListener('webkitfullscreenchange', this.fullscreenChanged);
        }
        setTimeout(() => {
            this.setState({
                exitedfs: false,
                waited: true
            });
        }, 750)
    }

    fullscreenChanged() {
        if (!document.fullscreenElement) {
            this.setState({
                exitedfs: true,
            })
        }
    }

    componentDidMount() {
        axios.get(config.apiBase+"getseqs", {
            params: {
                type: this.props.type,
                numseqs: config.exp_numseqs
            }
        }).then((response: any) => {
            for (var x = 0; x<response['data'].length; x++) {
                for (var img = 0; img<response['data'][x].length; img++) {
                    new Image().src = response['data'][x][img]
                }
            }

            let prolific = false;
            let hitId: any = window.localStorage.getItem("RISE_HIT_ID");
            if (window.localStorage.getItem("RISE_HIT_ID")!.includes("PROLIFIC_")) {
                hitId = window.localStorage.getItem("RISE_HIT_ID")!.split('_')[1]
                prolific = true;
            }

            this.setState({
                sequences: response['data'],
                loaded: true,
                toSubmit: {"0": {}},
                hitid: hitId,
                prolific: prolific
            })
        })
    }

    getResponse(value: string) {
        let newToSubmit = this.state.toSubmit;
        newToSubmit[this.state.currentSequence.toString()][this.state.currentImage.toString()] = {
            imageURL: this.state.sequences[this.state.currentSequence][this.state.currentImage],
            response: value,
            screenSize: window.innerWidth.toString()+"x"+window.innerHeight.toString(),
            type: this.props.type,
            imageNumber: this.state.currentImage,
            time: Date.now()-this.time,
            hitid: this.state.hitid,
            prolific: this.state.prolific
        }

        if ((this.state.currentSequence+1 == this.state.sequences.length) && (this.state.currentImage+1 == this.state.sequences[this.state.currentSequence].length)) {
            this.bigRef.current.removeEventListener('webkitfullscreenchange', this.fullscreenChanged);
            (document as any).webkitExitFullscreen();
            this.setState({
                finished: true,
                hitid: "Loading your completion id..."
            });
            if (this.props.type == "practice") {
                return;
            }
            axios.post(config.apiBase+"postdata", JSON.stringify(newToSubmit), {
                headers: {
                    'content-type': 'application/json'
                }
            }).then((resp: any) => {
                window.localStorage.removeItem("RISE_HIT_ID")
                this.setState({
                    finished: true,
                    hitid: resp['data']['id']
                });
            })
            return;
        }

        let next = false;
        if (this.state.currentImage+1 == this.state.sequences[this.state.currentSequence].length) {
            next = true;
            newToSubmit[(this.state.currentSequence+1).toString()] = {}
        }
        
        this.setState({
            toSubmit: newToSubmit,
            dontKnow: (next) || (value == "DONT_KNOW") ? true : false,
            intersequencebreak: next ? true : false,
            currentImage: next ? 0 : this.state.currentImage+1,
            currentSequence: next ? this.state.currentSequence + 1 : this.state.currentSequence
        });
    }

    afterBreak() {
        this.setState({
            intersequencebreak: false
        });
    }

    getCurrent() {
        if (this.state.exitedfs) {
            return (
                <div style={{textAlign: "center", fontFamily: "Helvetica"}}><h1>You must be in full screen to continue.</h1><button onClick={this.readyToStart}>Enter full screen</button></div>
            )
        }
        if (this.state.intersequencebreak) {
            return(
                <InterSequenceBreak next={this.afterBreak} outOf={(this.state.currentSequence).toString()+" of "+(this.state.sequences.length).toString()}/>
            )
        }
        if (this.state.finished) {
            return (
                <Finished hitId={this.state.hitid} type={this.props.type}/>
            )
        }

        if (!this.state.loaded) {
            return (
                <div style={{textAlign: "center", fontFamily: "Helvetica"}}><h1>Loading the experiment...</h1></div>
            )
        } else if (!this.state.waited) {
            return (
                <div style={{textAlign: "center", fontFamily: "Helvetica"}}><h1>Ready to start with your first sequence?</h1><button onClick={this.readyToStart}>Let's go!</button></div>
            )
        } else if (this.state.waited) {
            this.time = Date.now();
            return (
                <ImageShow dontKnow={this.state.dontKnow} onNext={this.getResponse} src={this.state.sequences[this.state.currentSequence][this.state.currentImage]} />
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    render() {
        return (
            <div ref={this.bigRef} style={{width: "100%", height:"100%", backgroundColor: "white"}}>
                {this.getCurrent()}
            </div>
        )
    }
}

export default Exp;