import { config } from '../../scripts/config';
import ImageOptions from '../ImageOptions';
import '../../styles/ImageShow_Style.css';

interface ImageShowProps {
    src: string,
    onNext: any,
    dontKnow: boolean
}

function ImageShow(props: ImageShowProps) {
    const submitAnswer = (answer: string) => {
        props.onNext(answer);
    }

    return (
        <div>
            <div style={{textAlign: "center", paddingTop: window.innerHeight/2-config.imageSqH/2}}>
                <img id="ImageShowIMG" src={props.src} width={config.imageSqH} height={config.imageSqH}/>
            </div>
            <ImageOptions dontKnow={props.dontKnow} next={submitAnswer}/>
        </div>
    )
}

export default ImageShow;