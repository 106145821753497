import InstructionNext from "../InstructionNext";

interface FinishedProps {
    hitId: string,
    type: string
}

function Finished(props: FinishedProps) {

    const toExp = () => {
        window.location.href = "/exp"
    }

    if (props.type == "exp") {
        return (
            <div style={{width: "80%", fontFamily: "Helvetica", margin: "auto", textAlign: "center"}}>
                <h1 style={{color: "red", marginBottom: 0}}>You're done!</h1>
                <h3 style={{marginTop: 0, fontWeight: "normal"}}>Your completion ID: {props.hitId}
                <br/><br/>Thank you so much for your participation!<br/>
                Any questions? Message us through Prolific or email psinha@mit.edu.</h3>
            </div>
        )
    } else if (props.type == "practice") {
        return (
            <div style={{width: "80%", fontFamily: "Helvetica", margin: "auto", textAlign: "center"}}>
                <h1 style={{color: "red", marginBottom: 0}}>Awesome! Ready to move on?</h1>
                <h3 style={{marginTop: 0, fontWeight: "normal"}}>If you're ready to start the full study, click the button below.<br/><br/>
                <InstructionNext onClick={toExp}/></h3>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}

export default Finished;