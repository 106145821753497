import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Exp from './Exp';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />}/>
      <Route path="/exp" element={<Exp type="exp" />} />
      <Route path="/practice" element={<Exp type="practice" />}/>
    </Routes>
  </BrowserRouter>
);
