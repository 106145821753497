import InstructionNext from '../InstructionNext';

interface InstructionProps {
    nextOnClick: any,
}

function InstructionsPage(props: InstructionProps) {
    return (
        <div style={{fontFamily: "Helvetica", width: "80%", margin: "auto"}}>
            <h1 style={{marginBottom: 0}}><span style={{color: "blue"}}>Instructions</span></h1>
            <h3 style={{marginTop: 0, fontWeight: "normal"}}>In this experiment, you will be asked to classify sequences in which images will get progressively clearer. Your challenge is to try to recognize the image at the <u>earliest</u> possible point.
            <br/><br/>
            You will see two buttons under the displayed image: <span style={{color: "rgb(255, 87, 87)"}}>'Don't know'</span>, and <span style={{color: "rgb(77, 183, 63)"}}>'Guess'</span>. Click on <span style={{color: "rgb(255, 87, 87)"}}>'Don't know'</span> if you have absolutely no idea what the image depicts. 
            Click on <span style={{color: "rgb(77, 183, 63)"}}>'Guess'</span> otherwise. (We encourage you to guess, even if you are not feeling confident about your response.) 
            After you have offered a guess for an image, the next one will have an additional button underneath: <span style={{color: "rgb(140, 122, 100)"}}>'Same as previous image'</span>. 
            Click on this button if your guess for this image is the same as the one for the immediately preceding one. This button will disappear if you click <span style={{color: "rgb(255, 87, 87)"}}>'Don't know'</span> and won't return until you submit another guess. 
            You can, of course, change your guess at any point by pressing the <span style={{color: "rgb(77, 183, 63)"}}>'Guess'</span> button.
            <br/><br/>
            There are 10 sequences, each with 20 images. The whole session will take approximately 10 minutes. At the end, you will be presented with a completion code, which you will need to submit to be compensated for your participation.
            <br/><br/>
            Click the NEXT button to see a sample sequence.</h3>
            <div style={{textAlign: "center"}}>
                <InstructionNext onClick={props.nextOnClick}/>
            </div>
        </div>
    )
}

export default InstructionsPage