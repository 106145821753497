import { config } from "./config";

const axios = require("axios");

function hitid_setup(searchParams: any) {
    window.localStorage.removeItem("RISE_HIT_ID");

    if (searchParams.get("PROLIFIC_PID") != null) {
        window.localStorage.setItem("RISE_HIT_ID", "PROLIFIC_"+(searchParams.get("PROLIFIC_PID") as string));
    } else {
        axios.get(config.apiBase+"gethitid").then((response: any) => {
            window.localStorage.setItem("RISE_HIT_ID", response['data']);
        })
    }
}

export default hitid_setup