import React from "react";
import InstructionNext from "../InstructionNext";

interface ShowSampleProps {
    next: any
}

class ShowSample extends React.Component<ShowSampleProps, {}> {
    constructor(props: ShowSampleProps) {
        super(props);
    }

    render() {
        return (
            <div style={{fontFamily: "Helvetica", width: "80%", margin: "auto", textAlign: "center"}}>
                <h1 style={{marginBottom: 0, color: "blue"}}>Sample Sequence</h1>
                <h3 style={{fontWeight: "normal", marginTop: 0}}>You will be shown a sample sequence. The study window will look the same during the real experiment, although with different sequences.<br/><br/>Ready to start?<br/>
                <br/><br/><InstructionNext onClick={this.props.next}/></h3>
            </div>
        )
    }
}

export default ShowSample;